
export function renderPageNotFoundPage() {
  return function PageNotFound() {
    return <>
      <h1 className="text-3xl font-bold underline">
        404 Page Not Found Page
      </h1>
    </>
  }
}
